@import '../../scss/base.scss';

.footerContainer {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 80px 150px 5px 5px 20px;
  grid-gap: 1.5rem;
  width: 100%;
  padding: 50px 150px 60px 150px;
  background-color: #fffaf3;

  @media (max-width: 1270px) {
    grid-template-rows: 80px 220px 5px 60px 20px;
  }

  @include tablet {
    padding: 50px 30px 60px 30px;
  }

  @media (max-width: 625px) {
    padding: 40px 15px 45px 15px;
  }

  @include mobile {
    grid-gap: 0.5rem;
    padding: 40px 15px 30px 15px;
    grid-template-columns: 100%;
    grid-template-rows: 90px 80px 42% 145px 6px 80px 70px 20px;
  }

  .logoContainer {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-row: 1 / 2;
      grid-column: 1 / 1;
    }

    .logo {
      width: 181px;
      height: 41px;

      background-repeat: no-repeat;
      background-image: url('/mawoo-black.svg');

      @include mobile {
        width: 119px;
        height: 51px;
        background-image: url('/mawoo-black-mobile.svg');
        margin-left: unset;
      }
    }

    p {
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-size: 12px;
      line-height: 15px;
      color: #393939;
      width: 80%;

      @include mobile {
        margin: 10px auto 0;
        text-align: center;
        padding-left: unset;
        width: auto;
      }
    }
  }

  /* purge css start ignore */
  // BBB & Trustpilot
  .badges {
    grid-row: 2 / 3;
    grid-column: 1 / 3;

    @include mobile {
      grid-row: 6 / 7;
      grid-column: 1 / 1;
      display: flex;
      align-items: flex-start;
      margin-top: 8px;
    }
    .sectionTrustpilot {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      margin-bottom: 10px;
      .icon {
        background-image: url('/truspilot/logo.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 85px;
        height: 28px;
      }
      .starts {
        background-image: url('/truspilot/five-starts-truspilot.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 150px;
        height: 32px;
      }
      span {
        font-family: Circular Std Book, Helvetica, Arial, sans-serif;
        font-size: 12px;
        line-height: 15px;
        color: #393939;
        margin-bottom: 5px;
        b {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
        }
      }
    }

    .bbb {
      width: 120px;
      height: 55px;
      background-image: url('/footer/BBB.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      @include tablet {
        margin-top: 20px;
      }

      @include mobile {
        width: 190px;
        height: 48px;
        background-image: url('/BBB.png');
        background-size: contain;
      }
    }

    > div {
      iframe {
        height: 95px !important;
        width: 140px !important;

        @include mobile {
          height: 112px !important;
          width: 90% !important;
        }
      }
    }
  }

  //links
  .linksContainer {
    display: flex;
    justify-content: space-between;
    grid-row: 1 / 3;
    grid-column: 3 / 7;

    @include mobile {
      grid-row: 3/3;
      grid-column: 1/1;
      grid-gap: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: -170px;
    }

    .linksColumn {
      display: flex;
      width: 100%;
      flex-direction: column;

      @include mobile {
        border-bottom: solid 1px rgb(57, 57, 57, 0.25);
      }

      p {
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #393939;
        margin: 0;

        @include mobile {
          margin-top: -10px;
          line-height: 35px;
        }
      }

      .links,
      .linksFind {
        display: flex;
        flex-direction: column;

        @include mobile {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: 10px;
        }

        a {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 35px;
          color: #393939;
          text-decoration: none;
          padding-top: 10px;

          @include mobile {
            width: calc(100% / 2);
            padding-top: unset;
            line-height: 25px;
          }
        }

        a:hover {
          /* Green/Green */
          color: #00ad66;
        }
      }

      .linksFind {
        @include mobile {
          margin-bottom: 60vh;
        }
      }

      .links {
        @include mobile {
          margin-bottom: -40vh;
        }
      }
    }

    .firstColumnHeight {
      height: calc(100% / 4);

      > div {
        height: 40px;
      }

      @include mobile {
        height: calc(100% / 4.9);
      }
    }

    .secondColumnHeight {
      height: calc(100% / 3.5);

      @include mobile {
        height: calc(100% / 4.9);
      }

      > div {
        height: 100px;
      }
    }

    .thirdColumnHeight {
      height: calc(100% / 3.5);

      @include mobile {
        height: calc(100% / 4.9);
      }

      > div {
        height: 100px;
      }
    }
  }

  /* purge css end ignore */

  // NewsLetter
  .newsletter {
    grid-row: 1 / 3;
    grid-column: 7 / 10;

    @include mobile {
      grid-row: 4 / 5;
      grid-column: 1 / 1;
    }

    h3,
    h4 {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #393939;

      margin-bottom: 10px;

      @include mobile {
        margin-top: 10px;
      }
    }
  }

  p {
    font-family: Circular Std Book, Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #393939;
    margin-top: 10px;
  }

  form {
    box-sizing: border-box;
    width: 250px;
    height: 40px;
    max-width: 380px;
    margin-top: 20px;
    padding: 0 32.5px;
    /* BG/White */
    background: #ffffff;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1350px) {
      max-width: 230px;
    }

    @media (max-width: 1010px) {
      max-width: 200px;
    }

    @media (max-width: 850px) {
      max-width: 180px;
    }

    @include mobile {
      max-width: 300px;
      height: 40px;
      padding: 0 27.5px;
      border-radius: 27.5px;
    }

    input {
      width: calc(100% - 30px - 15px);
      border: none;
      outline: none;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 18px;
      /* Black */
      color: #393939;
      opacity: 0.8;

      @include mobile {
        font-size: 16px;
      }
    }

    .icon {
      width: 30px;
      height: 30px;
      background-image: url('/footer/input-arrow.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .buttons {
    margin-top: 20px;
    width: 300px;

    p {
      font-family: Circular Std Book;
      font-weight: bold;
      font-size: 16px;
    }
  }

  img {
    width: 36.81px;
    height: 40.56px;
  }
}

// Line
.lineSeparation {
  grid-row: 3 / 4;
  grid-column: 1 / 9;
  width: 100%;
  border-top: solid 1px rgb(57, 57, 57, 0.25);

  @include mobile {
    grid-row: 5 / 6;
    grid-column: 1 / 1;
  }
}

// Payment Methods
.payment {
  grid-row: 4 / 5;
  grid-column: 1 / 5;
  display: flex;
  align-items: center;

  @include mobile {
    grid-row: 7 / 8;
    grid-column: 1 / 1;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 50px;
    background-size: cover;
    margin-right: 10px;

    @include mobile {
      margin-right: 8px;
      width: 40px;
      height: 26px;
    }
  }
}

// Contact & Social
.contact {
  grid-row: 4 / 5;
  grid-column: 5 / 9;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mobile {
    grid-row: 2 / 3;
    grid-column: 1 / 1;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: -30px;
  }

  .phoneNumber {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font: 16px Circular Std Bld, Helvetica, Arial, sans-serif;
    color: #393939;
    text-decoration: none;
    white-space: nowrap;

    @include mobile {
      margin-top: 10px;
      margin-left: 30px;
    }

    .icon {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      background-image: url('/phone-black.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .icon:hover {
      height: 15px;
      width: 15px;
      background-image: url('/phone-green.svg');
    }
  }

  .phoneNumber:active,
  .phoneNumber:hover {
    color: #00ad66;
  }

  .social {
    display: flex;
    flex-direction: row;

    @include mobile {
      margin-left: 30px;
    }

    .iconFb,
    .iconInstagram,
    .iconTiktok,
    .iconPinterest {
      height: 28px;
      width: 28px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 20px;
      cursor: pointer;
    }

    .iconFb {
      background-image: url('/footer/fb.svg');
    }

    .iconInstagram {
      background-image: url('/footer/instagram.svg');
    }

    .iconTiktok {
      background-image: url('/footer/tiktok.svg');
    }

    .iconPinterest {
      background-image: url('/footer/pinterest.svg');
    }

    .iconFb:hover {
      background-image: url('/footer/fb_hover.svg');
    }

    .iconInstagram:hover {
      background-image: url('/footer/instagram_hover.svg');
    }

    .iconTiktok:hover {
      background-image: url('/footer/tiktok_hover.svg');
    }

    .iconPinterest:hover {
      background-image: url('/footer/pinterest_hover.svg');
    }
  }
}

// copyright
.copyRight {
  grid-row: 5 / 6;
  grid-column: 1 / 9;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;

  @include mobile {
    grid-row: 8 / 9;
    grid-column: 1 / 1;
    justify-content: center;
  }

  p {
    font-family: Circular Std Book, Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #393939;
    margin: 0;
  }
}
