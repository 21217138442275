@import "../../scss/base.scss";

.aTag {
  text-decoration: none;
}

.button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Circular Std Book;

  .content {
    font-family: Circular Std Book;
  }

  .title {
    font-family: Circular Std Book;
    font-weight: bold;
  }

  .subtitle {
    font-family: Circular Std Book;
  }

  .subtitle {
    font-family: Circular Std Book;
  }

  // HEIGHT
  // Extra tall
  &.extra-tall {
    height: 90px;
    border-radius: 45px;
    font-size: 18px;
    line-height: 23px;
    padding: 0 45px;

    @include mobile {
      height: 60px;
      border-radius: 30px;
      font-size: 16px;
      line-height: 20px;
      padding: 0 30px;
    }

    &.centerImage {
      width: 90px;

      @include mobile {
        width: 60px;
      }
    }

    .leftImage {
      max-width: 40px;
      margin-right: 20px;

      @include mobile {
        width: 25px;
        margin-right: 15px;
      }
    }

    .rightImage {
      max-width: 40px;
      margin-left: 20px;
      margin-right: 20px;

      @include mobile {
        width: 25px;
        margin-left: 15px;
      }
    }
  }

  // Tall
  &.tall {
    height: 75px;
    border-radius: 37.5px;
    font-size: 18px;
    line-height: 23px;
    padding: 0 37.5px;

    @include mobile {
      height: 60px;
      border-radius: 30px;
      font-size: 16px;
      line-height: 20px;
      padding: 0 30px;
    }

    &.centerImage {
      width: 75px;

      @include mobile {
        width: 60px;
      }
    }

    .leftImage {
      margin-right: 20px;

      @include mobile {
        margin-right: 15px;
      }
    }

    .rightImage {
      margin-left: 20px;

      @include mobile {
        margin-left: 15px;
      }
    }
  }

  // Large
  &.large {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 15px;
    padding: 0 30px;
    .rightImage {
      margin-left: 15px;
    }
  }

  // Medium
  &.medium {
    height: 50px;
    border-radius: 30px;
    font-size: 18px;
    line-height: 18px;
    padding: 0 30px;

    @include mobile {
      height: 50px;
      border-radius: 25px;
      font-size: 14px;
      line-height: 18px;
      padding: 0 25px;
    }

    &.centerImage {
      width: 50px;
      height: 60px;

      @include mobile {
        width: 40px;
        height: 40px;
      }
    }

    .leftImage,
    .rightImage {
      width: 18px;
      height: 18px;
    }

    .leftImage {
      margin-right: 15px;

      @include mobile {
        margin-right: 10px;
      }
    }

    .rightImage {
      margin-left: 15px;

      @include mobile {
        margin-left: 10px;
      }
    }
  }

  // Short
  &.short {
    height: 55px;
    border-radius: 27.5px;
    font-size: 14px;
    line-height: 18px;
    padding: 0 27.5px;

    @include mobile {
      height: 40px;
      border-radius: 20px;
      padding: 0 20px;
    }

    &.centerImage {
      width: 50px;

      @include mobile {
        width: 40px;

        > img {
          width: 15px;
        }
      }
    }

    .leftImage {
      margin-right: 10px;

      @include mobile {
        margin-right: 5px;
        width: 25px;
      }
    }

    .rightImage {
      margin-left: 10px;

      @include mobile {
        margin-left: 5px;
        width: 25px;
      }
    }
  }

  // Extra short
  &.extra-short {
    height: 45px;
    border-radius: 22.5px;
    font-size: 16px;
    padding: 0 22.5px;

    &.centerImage {
      width: 45px;
    }

    .leftImage {
      width: 15px;
      margin-right: 7.5px;
    }

    .rightImage {
      width: 15px;
      margin-left: 7.5px;
    }
  }

  // SPECIAL BUTTONS
  // Klarna
  &.klarna-in-4 {
    background: #feb3c7;
    background-image: url("/klarna-pay-later.svg");
    background-size: 196px 23px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.search-bar {
    height: 50px;
    border-radius: 27.5px;
    font-size: 14px;
    line-height: 18px;
    padding: 0 25px;

    @include mobile {
      height: 30px;
      border-radius: 20px;
      font-size: 12px;
      line-height: 15px;
      padding: 0 20px;
    }

    &.centerImage {
      width: 50px;

      @include mobile {
        width: 30px;

        > img {
          width: 15px;
        }
      }
    }

    .leftImage {
      margin-right: 10px;

      @include mobile {
        margin-right: 7.5px;
        width: 15px;
      }
    }

    .rightImage {
      margin-left: 10px;

      @include mobile {
        margin-left: 7.5px;
        width: 15px;
      }
    }
  }

  // Login / Sign up
  &.login {
    width: 100%;
    height: 60px;
    border-radius: 30px;
    font-size: 16px;
    margin-top: 15px;
    .title {
      font-family: Circular Std Book;
      font-weight: 400;
    }

    .leftImage {
      width: 26px;
      margin-right: 20px;
    }
    .rightImage {
      margin-left: 15px;
    }
  }

  // COLOR STYLES
  // Pink Solid
  &.pink-solid {
    background-color: #ff1e57;
    color: #ffffff;
  }

  &.pink-solid:hover {
    background-color: #e51b4e;
  }

  &.pink-solid-hollow {
    border: 1px solid #ff1e57;
    background-color: #fffefc;
    color: #ff1e57;
  }

  &.pink-solid-hollow:hover {
    border: 1px solid #e51b4e;
    background-color: #fffefc;
    color: #e51b4e;
  }

  // Pink
  &.pink {
    border: 2px solid #d01558;
    background-color: #ffffff;
    color: #d01558;
  }

  &.pink:hover {
    border: 2px solid #a61146;
    color: #a61146;
  }

  &.pink:active {
    border: none;
    background-color: #d01558;
    color: #ffffff;
  }

  // Gold Solid
  &.gold-solid {
    background-color: #f5b800;
    color: white;
  }

  // Yellow Solid
  &.yellow-solid {
    background-color: #ffcd31;
    color: #6323b7;
  }

  &.yellow-solid:hover {
    background-color: #ffcd31;
  }

  &.yellow-solid:active {
    background-color: #6323b7;
    color: #ffcd31;
  }

  // White
  &.white {
    background-color: white;
    border: 1px solid #ffffff;
    color: #00ad66;
  }

  // Light Green
  &.light-green {
    background-color: hsla(161, 92%, 84%, 1);
    color: #393939;
  }

  // Green
  &.green {
    background-color: white;
    border: 2px solid #00ad66;
    color: #00ad66;
  }

  &.green:hover {
    border: 2px solid #008c54;
    color: #008c54;
  }

  // Green Solid
  &.green-solid {
    background-color: #00ad66;
    color: #ffffff;
  }

  &.green-solid:hover {
    background-color: #008c54;
  }

  &.green-special {
    background-color: #00ad66;
    color: #ffffff;
  }

  &.green-special:hover {
    background-color: white;
    border: 2px solid #00ad66;
    color: #00ad66;
  }

  // Black
  &.black {
    background-color: white;
    border: 2px solid #393939;
    color: #1a1a1a;
  }

  &.black:hover {
    border: 2px solid #1a1a1a;
    color: #1a1a1a;
  }

  &.basic-black {
    background-color: white;
    border: 1px solid #393939;
  }

  &.black-login {
    background-color: white;
    border: 2px solid #39393965;
    color: #393939;
  }

  &.black-login:hover {
    border: 2px solid #393939;
    color: #393939;
  }

  &.black-letter {
    background-color: white;
    border: none;
    color: #393939;
  }

  &.blackSpecial {
    background-color: white;
    border: 2px solid #393939;
    color: #393939;
  }

  &.blackSpecial:hover,
  .blackSpecial:focus {
    border: 2px solid #a6e8cd;
    color: #1a1a1a;
  }

  &.blue {
    background-color: hsla(210, 100%, 32%, 1);
    color: #ffffff;
  }

  // Grey
  &.grey-solid {
    background-color: rgb(57, 57, 57, 0.5);
    color: #ffffff;
  }
  &.grey-border {
    border: 2px solid rgb(57, 57, 57, 0.5);
    color: rgb(57, 57, 57, 0.5);
    background-color: white;
    cursor: not-allowed;
  }

  // Toast
  &.toast {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
  }

  // Disabled
  &.disabled {
    cursor: not-allowed;
    background: rgb(57, 57, 57, 0.5);
  }

  &.disabled:hover {
    background: rgb(57, 57, 57, 0.5);
  }
}
